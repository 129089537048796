import { useEffect, useState } from "react";
import "./App.css";
import NavBar from "./components/NavBar";
import About from "./components/About";
import MyWork from "./components/MyWork";
import Contact from "./components/Contact";

import wave from "./assets/imgs/wave.png";
import BlobImage from "./components/BlobImage";

const NAVIGATION_INITIAL_STATE = [
  { name: "about", current: false, id: "about" },
  { name: "my work", current: false, id: "my-work" },
  { name: "contact", current: false, id: "contact" },
];

function App() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentNavigation, setCurrentNavigation] = useState(
    NAVIGATION_INITIAL_STATE
  );
  const [topMostDivID, setTopMostDivID] = useState("");
  const [mobView, setMobView] = useState(false); // Where nav turns into burger menu

  const handleNavStateUpdate = (id?: string) => {
    const updatedObj = currentNavigation.map((item) => ({
      ...item,
      current: item.id === id,
    }));
    setCurrentNavigation(updatedObj);
  };

  const handleScrollTo = async (divID: string) => {
    const div = document.getElementById(divID);
    if (div) {
      const y = div.getBoundingClientRect().top;
      if (mobView) {
        div.scrollIntoView();
      } else {
        div.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const updateDivAtTop = () => {
    const sections = [].slice.call(
      document.getElementsByClassName("page")
    ) as Element[];
    sections.forEach((element) => {
      const elStats = element.getBoundingClientRect();
      if (elStats.top <= 65 && elStats.height > 0) {
        // 65 because the nav is 64px
        setTopMostDivID(element.id);
      }
    });
  };

  useEffect(() => handleNavStateUpdate(topMostDivID), [topMostDivID]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    updateDivAtTop();
  };

  const updateMobViewState = () => {
    const width = window.innerWidth;
    if (width > 640) setMobView(false);
    else setMobView(true);
  };

  useEffect(() => {
    // Setting inital state here if user's screen is alredy in considered 'mobView'
    if (window.innerWidth < 640) setMobView(true);
    // Adding listeners here
    window.addEventListener("scroll", handleScroll, { passive: true });
    window.addEventListener("resize", updateMobViewState);
    return () => {
      console.log("I see u peeking 👀👀...");
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateMobViewState);
    };
  }, []);

  return (
    <div id="top" className="bg-black flex flex-col items-center">
      <NavBar handleScrollTo={handleScrollTo} navigation={currentNavigation} />
      <div className="sm:mx-10 bg-black px-6 lg:px-20 flex flex-col max-w-6xl">
        <div className="SPLASH-INTRO page h-screen flex flex-col justify-around lg:flex-row lg:justify-between items-center">
          <div className="LEFT-SIDE flex flex-col lg:space-y-0 space-y-12 text-center lg:text-left lg:w-1/2">
            <h1 className="lg:mb-4 text-5xl font-extrabold text-gray-900 dark:text-white md:text-6xl lg:text-7xl pt-7">
              <span className="text-transparent bg-clip-text bg-gradient-to-r to-violet-600 from-sky-400">
                zakariyya ahmed
              </span>
            </h1>
            <p className="text-xl font-medium text-red-500 lg:text-2xl dark:text-gray-400">
              Full Stack Software Engineer | Digital Artist | London | New York
              |{" "}
              <span className="align-middle inline-flex self-center items-center gap-x-1.5 rounded-md bg-green-500/10 px-2 py-1 text-sm font-medium text-green-400 ring-1 ring-inset ring-green-500/20">
                <svg
                  className="h-1.5 w-1.5 fill-green-400"
                  viewBox="0 0 6 6"
                  aria-hidden="true">
                  <circle cx={3} cy={3} r={3} />
                </svg>
                Open to work
              </span>
            </p>
          </div>
          <div className="RIGHT-SIDE ">
            <BlobImage src={wave} h={500} w={500}>
              <path
                fill="#7841ed"
                d="M36.3,-60.6C47.6,-49.3,57.4,-40,62.1,-28.7C66.8,-17.3,66.4,-3.9,64.2,9C62,22,58.1,34.5,51.2,46.9C44.3,59.3,34.5,71.7,21.6,77.3C8.6,83,-7.5,81.8,-24,78.5C-40.5,75.2,-57.3,69.7,-68,58.4C-78.7,47.1,-83.2,29.8,-85.6,12.4C-88,-5,-88.2,-22.7,-82.2,-38.3C-76.3,-53.9,-64.1,-67.4,-49.4,-77.1C-34.6,-86.7,-17.3,-92.6,-2.4,-88.9C12.6,-85.2,25.1,-71.9,36.3,-60.6Z"
                transform="translate(100 100)"
              />
            </BlobImage>
          </div>
        </div>
        <About />
        <MyWork />
        <Contact />
        {scrollPosition !== 0 && (
          <div
            onClick={() => {
              handleScrollTo("top");
            }}
            className="fixed bottom-5 right-7 z-50 cursor-pointer bg-violet-500 p-3 text-white rounded-full"
          >
            <ChevronUp />
          </div>
        )}
      </div>
    </div>
  );
}

const ChevronUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 15.75l7.5-7.5 7.5 7.5"
      />
    </svg>
  );
};

export default App;
