import clsx from "clsx";
import { ReactNode, FC, MouseEvent, useState } from "react";


interface BlobImageProps {
  src: string;
  className?: string;
  h?: number;
  w?: number;
  children: ReactNode;
}

const BlobImage: FC<BlobImageProps> = ({ className, src, children, h, w }) => {
  // This will be the x and y specific to the blob image
  const [x, setx] = useState(0);
  const [y, sety] = useState(0);

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    // console.log('dsfsdfs',e.clientX,e.clientY)
    const rect = e.currentTarget.getBoundingClientRect();
    // console.log(rect)
    setx(e.clientX - rect.left);
    sety(e.clientY - rect.top);
  };

  const height = h || 350;
  const width = w || 350;

  return (
    <div
      onMouseMove={handleMouseMove}
      // onMouseLeave={()=>{
      //   setx(0)
      //   sety(0)
      // }}
      className={clsx(className, "relative")}
    >
      <div className="w-full bg-lime-400">
        <div className="svg-container absolute w-full">
          <svg
            style={{
              transform: `translateX(${-0.015 * x}px) translateY(${
                y * -0.015
              }px)`,
            }}
            className=""
            viewBox="0 0 200 200"
            fill="#7841ed"
            xmlns="http://www.w3.org/2000/svg"
          >
            {children}
          </svg>
        </div>
      </div>
      <img
        src={src}
        className={clsx("ease-linear duration-300 translate-y-14 z-20")}
        style={{
          transform: `translateX(${0.02 * x}px) translateY(${y * 0.02}px)`,
        }}
        // style={{transform:'translateZ(42px)',scale: 0.2}}
        translate={"yes"}
        width={width - 10 + "px"}
        height={height - 10 + "px"}
        alt={src}
      />
    </div>
  );
};

export default BlobImage;
