import PageLayout from "./PageLayout";
import BlobImage from "./BlobImage";
import call from "../assets/imgs/call.png";
import Tilt from "react-parallax-tilt";

const Contact = () => {
  return (
    <PageLayout id="contact" name="contact">
      <div className="min-h-[85vh] h-fit gap-10 flex flex-col justify-around lg:flex-row lg:justify-between items-center">
        <div className="LEFT-SIDE flex flex-col lg:space-y-0 space-y-12 text-center lg:text-left lg:w-1/2">
          <BlobImage src={call} h={450} w={450}>
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M40.4,-66.7C53,-62.7,64.4,-53.2,73.5,-41.2C82.6,-29.1,89.6,-14.6,88.3,-0.7C87,13.1,77.5,26.2,67.7,37.2C58,48.1,48,57,36.7,64.3C25.4,71.5,12.7,77.1,0,77.2C-12.7,77.2,-25.5,71.7,-39.8,66.2C-54.1,60.7,-70,55.1,-73.3,44.1C-76.6,33.1,-67.2,16.5,-66.4,0.5C-65.5,-15.6,-73.2,-31.1,-71.6,-45C-69.9,-58.9,-59,-71,-45.5,-74.5C-32,-78,-16,-72.9,-1.1,-71C13.9,-69.2,27.8,-70.7,40.4,-66.7Z"
                transform="translate(100 100)"
              />
            </svg>
          </BlobImage>
        </div>
        <div className="RIGHT-SIDE">
          <ContactCard />
        </div>
      </div>
    </PageLayout>
  );
};
const socials = [
  {
    title: 'LinkedIn',
    link: 'https://www.linkedin.com/in/zakariyya-ahmed/',
    svg:<svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 24 24"
  >
    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z" />
  </svg>
  },
  {
    title:'GitHub',
    link:'https://github.com/zak1999',
    svg:<svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 24 24"
  >
    <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
  </svg>
  },
  {
    title: 'Email',
    link: 'mailto:zakariyya_ahmed@outlook.com',
    svg: <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 24 24"
  >
    <path d="M12 .02c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.99 6.98l-6.99 5.666-6.991-5.666h13.981zm.01 10h-14v-8.505l7 5.673 7-5.672v8.504z" />
  </svg>
  },
]


const ContactCard = () => {
  return (
    <Tilt tiltMaxAngleX={3} tiltMaxAngleY={3}>
      <div className="w-full max-w-sm p-4 bg-white border rounded-lg shadow sm:p-6 ">
        <h5 className="mb-7 text-base font-semibold text-gray-800 md:text-xl ">
          Reach me at any of the following!
        </h5>
        <ul className="my-4 space-y-3">
          {socials.map((item)=>  
          <li  key={item.link}> 
            <a
              href={item.link}
              className="flex gap-3 
                items-center p-3 text-base font-semibold 
                text-gray-800 rounded-lg bg-gray-200 
                hover:bg-gray-300 group hover:shadow "
            >
              {item.svg}
              <p>{item.title}</p>
            </a>
          </li>)}
        </ul>
      </div>
    </Tilt>
  );
};

export default Contact;
