import { FC } from "react";
import Tilt from "react-parallax-tilt";

interface CardProps {
  title: string;
  description: string;
  img: string;
  stack: string[];
  url?: string;
}

const Card: FC<CardProps> = ({ title, description, img, stack, url }) => {
  return (
    <Tilt tiltMaxAngleX={3} tiltMaxAngleY={3}>
      {/* MEDIUM+ SCREENS */}
      <div className="hidden lg:flex lg:flex-col 
      lg:justify-between max-w-sm min-h-full 
      rounded-lg overflow-hidden shadow-lg 
      bg-white p-1 m-auto">
        <img
          className="object-cover w-auto h-[250px]"
          src={img}
          alt={`Image of ${title}`}
        />
        <div className="px-6 py-4">
          
          <h4 className=" flex gap-2 items-center font-bold text-xl mb-2 text-gray-800">
            {title}{" "}
            {url ? (
              <a href={url}>
                <LinkIcon />
              </a>
            ) : (
              ""
            )}
          </h4>
          <p className="text-gray-700 text-base">{description}</p>
        </div>
        <div className="px-6 pt-4 pb-2">
          {stack.map((item) => (
            <span
              key={item}
              className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
            >
              {item}
            </span>
          ))}
        </div>
      </div>
      {/* SMALLER SCREEN */}
      <div className=" max-w-[100%] lg:hidden flex self-center">
        <div className="rounded-l overflow-hidden w-[30%]">
          <img
            className="object-cover w-full h-[100%]"
            src={img}
            alt={`Image of ${title}`}
          />
        </div>
        <div className="flex flex-col w-[70%] border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 ">
          <div className="mb-5">
            <div className="flex gap-2 items-center text-gray-900 font-bold text-xl mb-2">
              {title}{" "}
              {url ? (
                <a className="" href={url}>
                  <LinkIcon />
                </a>
              ) : (
                ""
              )}
            </div>
            <p className="text-gray-700 text-base">{description}</p>
          </div>
          <div className="flex flex-wrap">
            {stack.map((item) => (
              <span
                key={item}
                className="bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
              >
                {item}
              </span>
            ))}
          </div>
        </div>
      </div>
    </Tilt>
  );
};

const LinkIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-3 h-3"
      >
        <path
          fillRule="evenodd"
          d="M15.75 2.25H21a.75.75 0 01.75.75v5.25a.75.75 0 01-1.5 0V4.81L8.03 17.03a.75.75 0 01-1.06-1.06L19.19 3.75h-3.44a.75.75 0 010-1.5zm-10.5 4.5a1.5 1.5 0 00-1.5 1.5v10.5a1.5 1.5 0 001.5 1.5h10.5a1.5 1.5 0 001.5-1.5V10.5a.75.75 0 011.5 0v8.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V8.25a3 3 0 013-3h8.25a.75.75 0 010 1.5H5.25z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default Card;
