import PageLayout from "./PageLayout";
import Card from "./Card";
import BlobImage from "./BlobImage";

import laptop from "../assets/imgs/laptop.png";
import up4it from "../assets/imgs/up4it.jpeg";
import birdi from "../assets/imgs/birdi-1.jpg";
import frameit from "../assets/imgs/frame-it.png";

const projects = [
  {
    title: "Up4it",
    description:
      "An app that intelligently suggests activities based on user actions and preferences using ML. Developed registration and profile pages, integrated Google Maps and Places API.",
    img: up4it,
    stack: [
      "TypeScript",
      "Svelte",
      "Python",
      "Node JS",
      "Koa",
      "MongoDB",
      "Mongoose",
      "Google Maps API",
      "Google Places API",
      "PWA",
    ],
  },
  {
    title: "FrameIt",
    description:
      "FrameIt allows users to share images securely. Improved codebase through refactoring and modularization, migrated from JavaScript to TypeScript and implemented test coverage.",
    img: frameit,
    stack: [
      "React",
      "Auth0",
      "Node JS",
      "SQL",
      "Jest",
      "Cypress",
      "TypeScript",
      "JavaScript",
    ],
    url: "https://github.com/zak1999/FrameIt",
  },
  {
    title: "Birdi",
    description: `I Built Birdi to help birdwatchers find birds and other enthusiasts nearby. 
      Integrated Wiki's, eBird's, Auth0's and Mapbox's APIs to improve functionality. 
      Launched the app from ideation to production.`,
    img: birdi,
    stack: [
      "React",
      "Redux",
      "Node JS",
      "Express",
      "MongoDB",
      "Mongoose",
      "Auth0",
      "Mapbox",
    ],
    url: "https://github.com/zak1999/birdi",
  },
];

const MyWork = () => {
  return (
    <PageLayout id="my-work" name="my work">
      <div className="flex items-center pb-7">
        <p className=" md:text-lg py-12 text-gray-300">
          Here are some cool projects and work I've been involved in. Check out
          my{" "}
          <a
            href="https://github.com/zak1999/"
            className="underline hover:text-violet-500 transition-all"
          >
            GitHub
          </a>{" "}
          for a few more smaller, nifty projects and If you're interested in
          more details about my specific responsibilities for any of these
          projects, feel free to take a look at my CV or{" "}
          <a
            className="underline hover:text-violet-500 transition-all"
            href="https://www.linkedin.com/in/zakariyya-ahmed/"
          >
            LinkedIn
          </a>
          .
          <br />
          I'm always open to connecting and chatting about anything else, so
          don't hesitate to reach out and get in touch!
        </p>
        <BlobImage className="hidden lg:block" src={laptop} w={250} h={250}>
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M39.8,-63.2C48.7,-56.2,51,-40.3,57.9,-26.1C64.8,-12,76.3,0.3,75.1,11C73.8,21.7,59.8,30.7,49.7,44C39.6,57.3,33.4,75,22.9,78.2C12.4,81.3,-2.4,70.1,-19.4,65.4C-36.5,60.8,-55.6,62.8,-67.4,55.1C-79.1,47.4,-83.4,30,-80.5,14.8C-77.6,-0.3,-67.5,-13.3,-59.5,-25.7C-51.5,-38.1,-45.6,-49.9,-36.1,-56.6C-26.5,-63.3,-13.2,-64.8,1.1,-66.6C15.5,-68.3,30.9,-70.2,39.8,-63.2Z"
              transform="translate(100 100)"
            />
          </svg>
        </BlobImage>
      </div>

      <div
        id="card-holder"
        className="flex flex-col gap-y-6 min-w-sm lg:grid lg:grid-cols-2 lg:gap-10"
      >
        {projects.map((proj) => (
          <Card key={proj.title} {...proj} />
        ))}
      </div>
    </PageLayout>
  );
};

export default MyWork;
