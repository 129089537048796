import clsx from "clsx";
import { ReactNode, FC } from "react";

type pageLayoutProps = {
  children: ReactNode;
  id: string;
  vh?: number;
  name: string;
};
const PageLayout: FC<pageLayoutProps> = ({ children, id, name }) => {
  return (
    <>
      <div
        id={id}
        className={clsx(
          `page min-h-[50vh] flex flex-col justify-between scroll-m-16 py-5 text-white`)}>
        <h2 className="text-3xl md:text-4xl font-extrabold text-violet-500">{name}</h2>
        {children}
      </div>
    </>
  );
};

export default PageLayout;
