import clsx from "clsx";
import { FC } from "react";
import { Disclosure, Transition } from "@headlessui/react";

interface NavBarProps {
  handleScrollTo: (divID: string) => void;
  navigation: {
    name: string;
    current: boolean;
    id: string;
  }[];
}



const NavBar: FC<NavBarProps> = ({ handleScrollTo, navigation }) => {
  return (
    <Disclosure
      as="nav"
      className="flex justify-end md:justify-around w-full fixed top-0 left-0 right-0 z-50"
    >
      {({ open }) => (
        <>
          <div className="max-w-7xl min-w-full sm:px-6 lg:px-8 backdrop-blur-sm bg-black/40 bg-opacity-[.15] w-screen">
            <div className=" flex flex-col-reverse h-16 items-center">
              <div className="sm:hidden self-end pr-10 justify-center">
                {/* Mobile menu button*/}
                <Disclosure.Button className="items-center p-2 justify-center rounded-md 
                text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none
                focus:ring-2 focus:ring-inset focus:ring-white ">
                  {open ? <XmarkIcon /> : <BurgerSVG />}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center">
                <div className="hidden md:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                        onClick={() => handleScrollTo(item.id)}
                        key={item.name}
                        className={clsx(
                          item.current
                            ? "text-violet-500 underline"
                            : "text-gray-300 hover:text-white",
                          " hover:bg-violet-950 rounded-md px-3 py-2 text-md font-medium cursor-pointer transition ease-in-out duration-300 "
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* DROPDOWN  */}
          <Disclosure.Panel className="sm:hidden min-w-screen">
            <div className="min-w-screen space-y-1 px-2 pb-3 pt-2 fixed top-16 flex flex-col items-center left-0 w-screen backdrop-blur-sm bg-black/40 bg-opacity-[.15]">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  onClick={() => handleScrollTo(item.id)}
                  className={clsx(
                    item.current
                      ? "text-violet-500 underline"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "hover:bg-violet-950 block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.id}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default NavBar;

const BurgerSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      // viewBox="0 0 12 12"
      strokeWidth={2}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
      />
    </svg>
  );
};

const XmarkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
};

const CodeSymbol = () => {
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5"
    />
  </svg>;
};