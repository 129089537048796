import meditate from "../assets/imgs/meditate.png";
import chefsKiss from "../assets/imgs/chefs-kiss.png";
import PageLayout from "./PageLayout";
import BlobImage from "./BlobImage";

const About = () => {
  return (
    <>
      <PageLayout id="about" name="about">
        <div className="justify-between pt-5 text-gray-300 items-center flex flex-col lg:flex-row ">
          <div className="WORD-PART-1 md:text-lg space-y-4 lg:max-w-[65%]">
            <p>Hey! Welcome to my site!</p>
            <p>
              I'm a Full Stack Software Engineer with expertise in building seamless digital services and experiences. 
              From concept to deployment, I thrive on collaborating with teams to create innovative products.
              I embrace the whole development lifecycle and love kepeing up with and trying out the latest technologies and tools.
            </p>
          </div>
          <BlobImage src={meditate}>
            <path
              d="M34.6,-52.7C48.8,-44.6,67.2,-41.9,73.5,-32.3C79.9,-22.8,74.1,-6.5,68.4,7.4C62.7,21.4,57,32.9,50.2,47C43.4,61.2,35.6,77.9,22.7,85.3C9.8,92.8,-8.2,90.9,-19.9,81.3C-31.7,71.7,-37.2,54.4,-40.7,40.7C-44.1,27,-45.5,16.8,-51.3,4.8C-57.1,-7.3,-67.3,-21.4,-68,-35.7C-68.7,-50,-59.8,-64.7,-46.9,-73.4C-34,-82.1,-17,-84.9,-3.4,-79.6C10.2,-74.2,20.3,-60.8,34.6,-52.7Z"
              transform="translate(100 100)"
            />
          </BlobImage>
        </div>
        <div className="justify-between pt-5 text-gray-300 items-center flex flex-col-reverse lg:flex-row">
          <BlobImage src={chefsKiss} className="hidden lg:block">
            <path
              d="M40.2,-60C52,-54.9,61.4,-43.6,66.7,-30.8C71.9,-18,73,-3.7,66.2,6C59.4,15.7,44.6,20.8,36.6,33.7C28.6,46.6,27.2,67.3,17.8,77.9C8.5,88.5,-8.9,89.1,-21.8,81.8C-34.6,74.6,-43,59.5,-45.2,45.5C-47.4,31.5,-43.6,18.6,-44.5,6.9C-45.4,-4.8,-51.1,-15.4,-53.3,-30.1C-55.5,-44.8,-54.2,-63.6,-44.8,-69.8C-35.3,-76,-17.7,-69.5,-1.7,-66.8C14.2,-64.1,28.4,-65.1,40.2,-60Z"
              transform="translate(100 100)"
            />
          </BlobImage>
          <div className="WORD-PART-2 md:text-lg space-y-4 lg:max-w-[65%]">
            <p>
              When not working, you might find me bouldering, snapping pics and
              videos of cool stuff, exploring new places and traveling,
              indulging in different foods, or, maybe just chilling and watching
              a movie.
            </p>
            <p>
              I built this website to showcase some of my work, share my experiences,
              and connect with like-minded individuals. Feel free to explore my
              work or reach out to me for potential collaborations or just a
              chat.
            </p>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default About;
